<template>
 <div class="ping">
      <el-table
    :data="tableData1"
    style="width: 100%" class="table1">
      <el-table-column label="权利人名称"  align="center">
        <el-table-column prop="shun" label="抽签顺序号" align="center">
        </el-table-column>
      </el-table-column>
      <el-table-column :label="tableData1[0].name">
        <el-table-column prop="bian" label="合同编号" align="center">
        </el-table-column>
        <el-table-column prop="date" label="抽签时点" align="center">
        </el-table-column>
        <el-table-column prop="tao" label="房屋总套数" align="center">
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column prop="zu" label="组团名称" align="center">
        </el-table-column>
        <el-table-column prop="lou" label="楼栋及单元号" align="center">
        </el-table-column>
        <el-table-column prop="fang" label="房号" align="center">
        </el-table-column>
        <el-table-column prop="mian" label="户型面积" align="center">
        </el-table-column>
    </el-table>
 </div>
</template>

<script>
export default {
  data () {
    return {
        tableData1: [{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          hu:'一室一厅',
          tao:5,
          zu:"1",
          lou:'2',
          fang:'1201',
          mian:'120'
        }],
         tableData: [{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          tao:3,
          zu:"团1",
          lou:'1栋3单元',
          fang:'1201',
          mian:'120'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          tao:3,
          zu:"团1",
          lou:'1栋3单元',
          fang:'1201',
          mian:'120'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          tao:3,
          zu:"团1",
          lou:'1栋3单元',
          fang:'1201',
          mian:'120'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          tao:3,
          zu:"团1",
          lou:'1栋3单元',
          fang:'1201',
          mian:'120'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '2020/12/23/08：30：38',
          name: '王小虎',
          tao:3,
          zu:"团1",
          lou:'1栋3单元',
          fang:'1201',
          mian:'120'
        },]
    }
  }
}

</script>
<style lang="less" scoped>
.ping{
    width: 100vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}
/deep/.el-table .has-gutter th,.el-table .has-gutter tr{
    background-color: #F5F7FA!important;
}
</style>
